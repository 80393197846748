import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import { FaCog, FaSlidersH, FaFilter, FaCogs, FaWrench, FaBolt, FaIndustry, FaBox } from 'react-icons/fa';
import './Categories.css';

const categories = [
  { name: 'Регуляторы', icon: <FaSlidersH /> },
  { name: 'Контроллеры', icon: <FaCog /> },
  { name: 'Цифровые регуляторы', icon: <FaFilter /> },
  { name: 'Комплектующие', icon: <FaCogs /> },
  { name: 'Фильтрующие элементы', icon: <FaWrench /> },
  { name: 'Электрогуру', icon: <FaBolt /> },
  { name: 'Hyundai товары', icon: <FaIndustry /> },
  { name: 'Инверторы', icon: <FaCog /> },
  { name: 'Товары с RS24', icon: <FaBox /> }
];

const Categories = ({ onSelectCategory }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="categories">
      <h2 className="categories__title">Категории</h2>
      <button className="categories__burger" onClick={toggleMenu}>
        <FaBars />
      </button>
      <div className={`categories__list ${isMenuOpen ? 'categories__list--open' : ''}`}>
        {categories.map((category, index) => (
          <div 
            key={index} 
            className="category" 
            onClick={() => {
              onSelectCategory(category.name);
              setIsMenuOpen(false); // Закрываем меню после выбора категории
            }}
          >
            <div className="category__icon">{category.icon}</div>
            <div className="category__name">{category.name}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Categories;