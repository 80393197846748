// src/components/Pagination.js
import React from 'react';
import './Pagination.css';

const Pagination = ({ productsPerPage, totalProducts, paginate, currentPage }) => {
  const totalPages = Math.ceil(totalProducts / productsPerPage);
  const maxPageNumbersToShow = window.innerWidth <= 768 ? 3 : 10; // 3 страницы на мобильной версии, 10 на десктопе
  const startPage = Math.max(1, currentPage - Math.floor(maxPageNumbersToShow / 2));
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className="pagination-nav">
      <ul className="pagination">
        {startPage > 1 && (
          <li className="page-item">
            <button onClick={() => paginate(1)} className="page-link">1</button>
          </li>
        )}
        {startPage > 2 && <li className="page-item dots">...</li>}
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button onClick={() => paginate(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
        {endPage < totalPages - 1 && <li className="page-item dots">...</li>}
        {endPage < totalPages && (
          <li className="page-item">
            <button onClick={() => paginate(totalPages)} className="page-link">{totalPages}</button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Pagination;