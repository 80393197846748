import React from 'react';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './ProductCard.css';

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <Link to={`/products/${product.id}`} className="product-card__link">
        <div className="product-card__image">
          <LazyLoad height={150} offset={10}>
            {product.img_url ? (
              <img src={product.img_url} alt={product.title} />
            ) : (
              '⚙️'
            )}
          </LazyLoad>
        </div>
        <div className="product-card__title">{product.title || 'Без названия'}</div>
        <div className="product-card__price">Цена: По запросу</div>
      </Link>
    </div>
  );
};

export default ProductCard;