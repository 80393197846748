// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Banner from './components/Banner';
import Categories from './components/Categories';
import ProductList from './components/ProductList';
import ProductDetail from './components/ProductDetail';
import Pagination from './components/Pagination';
import Footer from './components/Footer';
import About from './components/About';
import Contact from './components/Contact';
import './App.css';

const App = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(12);
  const [selectedCategory, setSelectedCategory] = useState('Все товары');

  useEffect(() => {
    fetch('/products.json')
      .then(response => response.json())
      .then(data => setProducts(data))
      .catch(error => console.error('Ошибка при загрузке данных:', error));
  }, []);

  const handleCategorySelect = category => {
    setSelectedCategory(category);
    setCurrentPage(1); // Сброс на первую страницу при смене категории
  };

  // Скролл наверх при изменении страницы
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage, selectedCategory]);

  const filteredProducts = selectedCategory === 'Все товары'
    ? products
    : products.filter(product => product.category === selectedCategory);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfFirstProduct + productsPerPage);

  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <Router>
      <div className="app">
        <Header />
        <div className="app-content">
          <Routes>
            <Route exact path="/" element={
              <>
                <Banner />
                <Categories onSelectCategory={handleCategorySelect} />
                <ProductList products={currentProducts} />
                <Pagination productsPerPage={productsPerPage} totalProducts={filteredProducts.length} paginate={paginate} currentPage={currentPage} />
              </>
            } />
            <Route path="/products/:id" element={<ProductDetail products={products} />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;