// src/components/Footer.js
import React from 'react';
import { FaMapMarkerAlt, FaEnvelope, FaPhone } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__links">
        <a href="/about">О нас</a>
        <a href="/contact">Контакты</a>
        {/* <a href="/privacy">Политика конфиденциальности</a> */}
      </div>
      <div className="footer__content">
        <div className="footer__map">
          <iframe
            src="https://yandex.ru/map-widget/v1/?um=constructor%3Axxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx&source=constructor"
            width="100%"
            height="150"
            frameBorder="0"
            title="Карта"
            allowFullScreen
          ></iframe>
        </div>
        <div className="footer__info">
          <p><FaMapMarkerAlt className="footer__icon" /> ООО "ДВ МЕХАНИКА"</p>
          <p><FaMapMarkerAlt className="footer__icon" /> ИНН: 4101192842 | ОГРН: 1204100002556</p>
          <p><FaMapMarkerAlt className="footer__icon" /> Юридический адрес: 683002, Камчатский край, Петропавловск-Камчатский г,</p>
          <p>Тундровая ул, дом 4А, помещение 1</p>
          <p><FaEnvelope className="footer__icon" /> Почтовый адрес: 683042, Камчатский край, Петропавловск-Камчатский г, а/я 1</p>
          <p><FaEnvelope className="footer__icon" /> Email: dvmehanika@example.com</p>
          <p><FaPhone className="footer__icon" /> Телефон: 8-914-020-77-99</p>
        </div>
      </div>
      <div className="footer__copyright">
        © 2024 ДВ Механика. Все права защищены.
      </div>
    </footer>
  );
};

export default Footer;