import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './ProductDetail.css';

const ProductDetail = ({ products }) => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const foundProduct = products.find(p => p.id === parseInt(id));
    setProduct(foundProduct);

    // Лог для проверки правильности идентификатора и найденного продукта
    console.log("ID из URL:", id);
    console.log("Найденный продукт:", foundProduct);
  }, [id, products]);

  if (!product) {
    return <div className="product-detail__not-found">Продукт не найден</div>;
  }

  return (
    <div className="product-detail-container">
      <div className="product-detail-image">
        {product.img_url ? (
          <img src={product.img_url} alt={product.title} />
        ) : (
          '⚙️'
        )}
      </div>
      <div className="product-detail-info">
        <h2 className="product-detail-title">{product.title || 'Без названия'}</h2>
        <div className="product-detail-description-container">
          <p className="product-detail-description">{product.description || 'Описание отсутствует'}</p>
        </div>
        <button className="product-detail-get-price">Запросить цену</button>
      </div>
    </div>
  );
};

export default ProductDetail;